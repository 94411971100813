import React from 'react';
import './styles.scss';

const DomainWHois = () => {
  return (
    <div className="tools-api-container">
      <h4 className="my-4 info-title" id="request-discover-whois">
        To make a request for Discover WHOIS result use this POST API.
      </h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">POST</span>{' '}
          https://api.hexomatic.com/tool-api/discover-whois?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexomatic{' '}
                <a className="info-title" href="https://dash.hexomatic.com/settings" no-index>
                  settings{' '}
                </a>
                API/Webhook section and copy your key
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Request Body</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">source</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">you should provide a domain</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"source": "example.com"</p>
        <p>{'}'}</p>
      </pre>
      <h5>Response Body</h5>
      <p>
        if request is <strong className="info-title">successful</strong> you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"taskId": "13f4b463-7e8a-429a-b62b-4afde618a154"</p>
        <p>{'}'}</p>
      </pre>

      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"statusCode": 403,</p>
        <p className="mgl-25">"message": "Forbidden"</p>
        <p>{'}'}</p>
      </pre>

      <h4 className="my-4 info-title" id="get-discover-whois">
        To get the result for Discover WHOIS use this GET API.
      </h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexomatic.com/tool-api/result/&#123;TASK_ID&#125;?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexomatic{' '}
                <a href="https://dash.hexomatic.com/settings" no-index>
                  settings
                </a>{' '}
                and API/Webhook section and copy your key
              </p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">TASK_ID</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                if you did Discover WHOIS POST request you must have received <strong>taskId</strong> that is valid one
                month after making the POST request. You can try to make this GET request with apx 5 minutes frequency
                after making the POST request for the tool.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Response Body</h5>
      <p>if request is successful you will get</p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"result": {'{'}</p>
        <p className="mgl-50"> "_whois_url": "https://example.com/"</p>
        <p className="mgl-50"> "_whois_domain": "example.com"</p>
        <p className="mgl-50"> "_whois_status": "Registered"</p>
        <p className="mgl-50"> "_whois_exists": true</p>
        <p className="mgl-50"> "_whois_expiresAt": "2023-01-19T06:23:59.000Z"</p>
        <p className="mgl-50"> _whois_expirationDays": 418</p>
        <p className="mgl-50"> _whois_lastModified": "2021-02-07T10:33:30.000Z"</p>
        <p className="mgl-50"> _whois_registeredAt": "2019-01-19T06:23:59.000Z"</p>
        <p className="mgl-50"> _whois_fullText": "DOMAIN_FULL_INFORMATION"</p>
        <p className="mgl-50"> _whois_dnsData": "DNS_DATA"</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>
      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"statusCode": 403,</p>
        <p className="mgl-25">"message": "Forbidden"</p>
        <p>{'}'}</p>
      </pre>
    </div>
  );
};

export default DomainWHois;
